import { parse } from 'qs';
// eslint-disable-next-line import/no-cycle
import { setSameDomain } from './token';

// eslint-disable-next-line import/prefer-default-export
export const generateId = (): any => {
  const cookieId = localStorage.getItem('cookie_id');
  if (!cookieId) {
    const newCookieId = `cookie_id${new Date().getTime()}`;
    localStorage.setItem('cookie_id', newCookieId);
  }
  return cookieId;
};

export const getQueryString = (name: string): any => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};

// 获取最大和最小的 随机整数
export const randomIntFromInterval = (min: number, max: number): number => {
  // min和max为范围的最小值和最大值
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isProduction = () => process.env.NEXT_PUBLIC_STAGE === 'prod';

interface Env {
  prod: string;
  test: string;
  develop: string;
}

// const envOrigin = {
//   prod: 'https://www.wukongsch.com',
//   test: 'https://wwww-test.wukongedu.net',
//   develop: 'https://wwww-dev.wukongedu.net',
// };

const stuEnvOrigin = {
  prod: 'https://student.wukongsch.com',
  prod_edu: 'https://student.wukongedu.net',
  test: 'https://student-test.wukongedu.net',
  develop: 'https://student-dev.wukongedu.net',
};

export const getStuCenterUrl = (from?: string) => {
  setSameDomain();
  const fromParam = from || 'official_website';
  const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';
  let origin = stuEnvOrigin[(process.env.NEXT_PUBLIC_STAGE as keyof Env) || 'develop'];
  if (process.env.NEXT_PUBLIC_STAGE === 'prod' && window.location.hostname.includes('wukongedu.net')) {
    origin = stuEnvOrigin.prod_edu;
  }
  return `${origin}/${lang}/?from=${fromParam}`;
};

export const isIOS = () => {
  const { userAgent } = navigator;
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const getLoginJumpUrl = (from?: string) => {
  const fromParam = from || 'official_website';
  const { return_url: returnUrl } = parse(window?.location?.search?.slice(1) || '');
  if (returnUrl) {
    setSameDomain();
    let decodeUrl = decodeURIComponent(returnUrl as string);
    // 如果是学员嵌入互动作业地址，直接跳学员中心首页
    if (decodeUrl.includes('/iexercise?')) {
      return getStuCenterUrl();
    }

    const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';

    // 如果returnUrl上无语言信息，需要强当前语言信息加上
    if (!decodeUrl.includes(`/en`) && !decodeUrl.includes(`/zh`)) {
      const origin =
        isProduction() && window.location.hostname.includes('wukongsch.com') ? 'wukongsch.com' : 'wukongedu.net';
      decodeUrl = decodeUrl.replace(`${origin}`, `${origin}/${lang}`);
    }
    return decodeUrl.includes('?') ? `${decodeUrl}&from=${fromParam}` : `${decodeUrl}?from=${fromParam}`;
  }
  return getStuCenterUrl();
};
