import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import { throttle } from 'lodash';
import styles from './style.module.scss';
import Modal from '@/components/Drawer';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import { loginByPhone } from '@/request/login';
import ToastLoading from '@/components/ToastLoading';
import { setTokenInfo } from '@/utils/token';
import { OtherLoginWays } from '@/request/interface/login';
// eslint-disable-next-line import/no-cycle
import BindAccountForm from '../BindAccountForm';
import { subscribe } from '@/request/commonGatWay';
import LoginContext from '@/context/LoginContext';
import RegistSuccess from '../RegistSuccess';
import ModalMobile from '@/components/ModalMobile';

interface Props {
  handleOpen: () => void;
  handleClose: () => void;
  handleLoginFinish: (notToStuCenter?: boolean) => void;
}

const Footer: FC<Props> = ({ handleOpen, handleClose, handleLoginFinish }) => {
  const { t } = useTranslation(['common']);
  const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);
  const [otherLoginMethod] = useState<OtherLoginWays>(['GOOGLE_ANDROID', 'APPLE']);
  const [params, setParams] = useState<any>({});
  const [bindAccountVisible, setBindAccountVisible] = useState<boolean>(false);
  // const initGoogleSdk = useRef(false);
  const googleBtnRef = useRef(null);
  const { googleLoginSDKLoaded, appleLoginSDKLoaded } = useContext(LoginContext);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [registType, setRegistType] = useState<'手机' | '微信' | '谷歌' | '谷歌提示' | '苹果'>('谷歌');

  const accreditSuccessCallback = async (
    code: string,
    type: 'GOOGLE_ANDROID' | 'APPLE_APP',
    source: 'GOOGLE' | 'APPLE',
  ) => {
    ToastLoading.show(t('加载中...'));
    try {
      const { content } = await loginByPhone({
        oauthType: type,
        code,
      });
      const { errorCode, token, oauthType = '', unionId = '', openId = '', email = '' } = content || {};
      ToastLoading.hide();
      if (errorCode === 409) {
        // onChangeLoginType(LoginType.bindEmail);
        // onChangeBindParams?.({ oauthType, unionId, openId });
        setParams({ oauthType, unionId, openId });
        setBindAccountVisible(true);
        handleClose();
      } else if (token) {
        // onLoginFinish(token, refreshToken, email);
        setTokenInfo(token);
        if (content?.accountData?.loginOrRegister === 'login') {
          handleLoginFinish();
        } else {
          setRegistType(type === 'GOOGLE_ANDROID' ? '谷歌' : '苹果');
          setSuccessVisible(true);
          handleLoginFinish(true);
        }
        subscribe({ email, source });
        handleClose();
      }
    } finally {
      ToastLoading.hide();
    }
  };

  const handleGoogleBtnLClick = throttle(
    useCallback(() => {
      traceEvent({ ak: 'official_mobile', data: { _event: 'O_GoogleClick', popup_name: '登录弹窗' } });
    }, []),
    500,
  );

  const initGoogleLogin = () => {
    window?.google?.accounts?.id?.initialize?.({
      client_id: '539432422945-v4n3mh2o3j6goqhslsggiup6c75jv7ro.apps.googleusercontent.com',
      callback: async (res: any) => {
        accreditSuccessCallback(res?.credential, 'GOOGLE_ANDROID', 'GOOGLE');
      },
    });

    window?.google?.accounts?.id?.renderButton?.(googleBtnRef.current, {
      theme: 'outline',
      size: 'large',
      logo_alignment: 'left',
      type: 'icon',
      width: 44,
      shape: 'rectangular',
      click_listener: handleGoogleBtnLClick,
    });
  };

  const initAppleLogin = () => {
    window?.AppleID?.auth?.init?.({
      clientId: 'com.wukongedu.wkofficialsite',
      scope: 'email',
      redirectURI: window.location.origin,
      state: 'initial',
      // nonce: '[NONCE]',
      usePopup: true,
    });
  };

  const handleOpenApple = async () => {
    try {
      traceEvent({ ak: 'official_mobile', data: { _event: 'O_AppleClick', popup_name: '登录弹窗' } });
      const data: any = await window?.AppleID?.auth?.signIn?.();
      if (data?.authorization?.id_token) {
        accreditSuccessCallback(data?.authorization?.id_token, 'APPLE_APP', 'APPLE');
      } else {
        console.error('苹果授权登录失败');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appleLoginSDKLoaded && otherLoginMethod?.includes?.('APPLE')) {
      initAppleLogin();
    }
  }, [appleLoginSDKLoaded]);

  useEffect(() => {
    if (googleLoginSDKLoaded && otherLoginMethod?.includes?.('GOOGLE_ANDROID')) {
      initGoogleLogin();
    }
  }, [googleLoginSDKLoaded]);

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.register}>
          <span style={{ marginRight: 10 }}>{t('还没有账号?')}</span>
          <span
            onClick={() => {
              handleClose();
              setRegisterModalVisible(true);
            }}
            style={{ color: '#FF5353', cursor: 'pointer' }}
          >
            {t('点击注册')}
          </span>
        </div>
        <div className={styles.otherLoginTitle}>
          <div className={styles.line} />
          <span>{t('其他方式登录')}</span>
          <div className={styles.line} />
        </div>
        <div className={styles.loginBtnContainer}>
          <div className={styles.loginBtnContent}>
            {otherLoginMethod?.includes?.('GOOGLE_ANDROID') && googleLoginSDKLoaded && (
              <div className={classNames(styles.loginBtn, styles.googleBtn)}>
                <div className={styles.googlContent} ref={googleBtnRef} />
              </div>
            )}
            {otherLoginMethod?.includes?.('APPLE') && appleLoginSDKLoaded && (
              <div onClick={handleOpenApple} className={classNames(styles.loginBtn, styles.appleBtn)} />
            )}
            {/* {otherLoginMethod?.includes?.('FACEBOOK') && (
          <div onClick={handleOpenFacebook} className={classNames(styles.loginBtn, styles.facebookBtn)}>
            {t('Facebook登录')}
          </div>
        )} */}
            {/* {otherLoginMethod?.includes?.('WECHAT_APP') && (
              <div onClick={handleOpenWechat} className={classNames(styles.loginBtn, styles.wechatBtn)} />
            )} */}
          </div>
        </div>
        <div className={styles.privacy}>
          <div>{t('选择任意方式登录即代表您阅读并同意')}</div>
          <div>
            <span
              onClick={() => {
                traceEvent({ ak: 'official_mobile', data: { _event: 'O_UserAgreementClick', popup_name: '登录弹窗' } });
                window.open('https://student.wukongedu.net/agreement');
              }}
              className={styles.highlight}
            >
              {t('用户协议')}
            </span>
            <span style={{ margin: '0 5px' }}>{t('和')}</span>
            <span
              onClick={() => {
                traceEvent({ ak: 'official_mobile', data: { _event: 'O_PrivacyPolicyClick', popup_name: '登录弹窗' } });
                window.open('https://www.wukongsch.com/policy/?layout=no');
              }}
              className={styles.highlight}
            >
              {t('隐私政策')}
            </span>
          </div>
        </div>
      </div>
      <Modal
        visible={registerModalVisible}
        handleClose={() => setRegisterModalVisible(false)}
        handleReturn={() => {
          setRegisterModalVisible(false);
          handleOpen();
        }}
      >
        <Register
          handleOpen={() => setRegisterModalVisible(true)}
          handleClose={() => setRegisterModalVisible(false)}
          handleLoginFinish={handleLoginFinish}
        />
      </Modal>
      <Modal
        visible={bindAccountVisible}
        handleClose={() => setBindAccountVisible(false)}
        handleReturn={() => {
          setBindAccountVisible(false);
          handleOpen();
        }}
      >
        <BindAccountForm
          handleOpen={() => setBindAccountVisible(true)}
          handleClose={() => setBindAccountVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={params}
          bindType="login"
        />
      </Modal>
      <ModalMobile visible={successVisible} handleClose={() => setSuccessVisible(false)} width="90%">
        <RegistSuccess registType={registType} handleLoginFinish={handleLoginFinish} />
      </ModalMobile>
    </>
  );
};

export default Footer;
