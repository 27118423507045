import React from 'react';
import { traceEvent } from '@wk/wk-gatherer';
import styles from './style.module.scss';

const PrivacyConfirmTip = ({ t }: { t: any }) => {
  return (
    <div className={styles.privacyConfirmTip}>
      <div className={styles.description}>
        {t('请先同意')}
        <span
          onClick={() => {
            traceEvent({ data: { _event: 'O_UserAgreementClick', popup_name: '登录弹窗' } });
            window.open('https://student.wukongedu.net/agreement');
          }}
          className={styles.highlight}
        >
          {' '}
          {t('用户协议')}
        </span>{' '}
        {t('和')}{' '}
        <span
          onClick={() => {
            traceEvent({ data: { _event: 'O_PrivacyPolicyClick', popup_name: '登录弹窗' } });
            window.open('https://www.wukongsch.com/policy/?layout=no');
          }}
          className={styles.highlight}
        >
          {t('隐私政策')}
        </span>
      </div>
    </div>
  );
};

export default PrivacyConfirmTip;
