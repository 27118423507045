import { AxiosRequestConfig } from 'axios';
import { requestStu as client } from '@/request/index';
import {
  BindOtherWayReq,
  CountriesRes,
  EmailCodeLoginReq,
  EmailCodeLoginRes,
  LoginRes,
  SendEmailCodeReq,
  V2ForgetPwdReq,
  V2LoginReq,
  V2RegisterReq,
  V2SendCodeReq,
  V2VerifyCodeReq,
  WechatLoginReq,
  OthersLoginMethodRes,
  ContentData,
  StudentInfoRes,
  GetPictureRes,
  GetPictureReq,
  ReqCheckRes,
  ReqCheckReq,
} from './interface/login';

export const sendLoginEmailCode = (type: 'register' | 'login', data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code/login/${type}`, data)
    .then((res: any) => res.content);
};

export const sendEmailCode = (data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code`, data)
    .then((res: any) => res.content);
};

export const loginOrRegisterByEmail = (data: EmailCodeLoginReq, config?: AxiosRequestConfig): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login', data, config);
};

export const loginByEmail = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login/v2', data);
};

export const loginByPassword = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/email', data);
};

export const registerAccount = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/register', data);
};

export const forgetPassword = (data: Omit<EmailCodeLoginReq, 'email'>): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/recover_password', data);
};

export const getTokenByJsCode = (data: WechatLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/login/wechat/applet', data);
};

export const getTokenByRefreshToken = (): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/api/authorized/refresh_token');
};

export const switchStudent = (studentId: string) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/switch/${studentId}`);
};

export const checkHasPwd = () => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>('/api/acc/account/already_set_password')
    .then((res: any) => res.content);
};

export const getCountryList = () => {
  return client
    .get<ContentData<CountriesRes>, ContentData<CountriesRes>>(`/public/open/countries/zh/ddl/local`)
    .then((res: any) => res.content);
};

export const loginByPhone = (data: V2LoginReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/login`, { ...data, Origin: 'OTHERS' });
};

export const sendVerifyCodeV2 = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code/authentication`, data)
    .then((res: any) => res.content);
};

export const sendVerifyCodeV3 = (api: string, data: V2SendCodeReq) => {
  return client.post<EmailCodeLoginRes, EmailCodeLoginRes>(`${api}`, data).then((res) => res.content);
};

export const checkForgetPwd = (type: 'EMAIL' | 'MOBILE', target: string) => {
  return client
    .post<any, any>(`/public/authentication/password_forgot`, { target, contactType: type })
    .then((res: any) => res.content);
};

export const forgetPasswordV2 = (data: V2ForgetPwdReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/recover_password`, data);
};

export const registerAccountV2 = (data: V2RegisterReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/authentication/register', data);
};

export const checkPhoneExist = (data: V2RegisterReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/exist_account`, data)
    .then((res: any) => res.content);
};

export const checkVerifyCode = (data: V2VerifyCodeReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/verify`, data)
    .then((res: any) => res.content);
};

export const sendBindCode = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code`, data)
    .then((res: any) => res.content);
};

export const bindOtherWay = (data: BindOtherWayReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/bind`, data);
};

export const bindConnectWay = (data: V2SendCodeReq) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/change/email_phone`, data);
};

export const verifyPhone = (phone: string) => {
  return client.get<ContentData<boolean>, ContentData<boolean>>(`public/open/valid_phone/${phone}`);
};
/**
 * 获取三方登录方式
 */

export const getOthersLoginMethod = () => {
  return client
    .get<ContentData<OthersLoginMethodRes>, ContentData<OthersLoginMethodRes>>('/public/open/web/properties')
    .then((res: any) => res.content);
};

export const getStudentList = () => {
  return client.get<StudentInfoRes, StudentInfoRes>('api/acc/account/students').then((res) => res?.content);
};

export const getPicture = (data: GetPictureReq) => {
  return client.post<GetPictureRes, GetPictureRes>(`/captcha/get`, data).then((res) => res);
};

export const reqCheck = (data: ReqCheckReq) => {
  return client.post<ReqCheckRes, ReqCheckRes>(`/captcha/check`, data).then((res) => res);
};

export const getAccountInfo = () => {
  return client.get<LoginRes, LoginRes>('/api/acc/account').then((res) => res?.content);
};
