import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ParallaxProvider } from 'react-scroll-parallax';
import { DefaultSeo } from 'next-seo';
import { WKGatherer } from '@wk/wk-gatherer';
import { install, gtag } from 'ga-gtag';

import '../styles/reset.css';
import '../styles/globals.scss';

import PageWrapper from '@/components/PageWrapper';
import { MyContext, IContextProps } from '@/config/context-manager';
import SEO from '../../configs/next-seo.config';
import { DISABLE_LAYOUT_PATHS, GTM_ARGUS } from '@/utils/constants';
import { isProduction } from '@/utils/utils';

if (global.window && !global.window.IntersectionObserver) {
  // @ts-ignore
  import('intersection-observer');
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [initGTM] = useState<boolean>(false);
  const [kaamelGAAgree, setKaamelGAAgree] = useState<boolean>(false);
  const [kaamelGTMAgree, setKaamelGTMAgree] = useState<boolean>(false);
  const [frameworkData, setFrameworkData] = useState<{ framework?: 'GDPR' | 'CCPA' | ''; finished?: boolean }>();
  const { locale } = router;
  const value = { locale, framework: frameworkData, kaamelGAAgree, kaamelGTMAgree };

  const stopScale = () => {
    document.documentElement.addEventListener(
      'touchstart',
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false,
    );

    let lastTouchEnd = 0;
    document.documentElement.addEventListener(
      'touchend',
      (event) => {
        const now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false,
    );

    document.documentElement.addEventListener('gesturestart', (event) => {
      event.preventDefault();
    });
  };

  const openVConsole = () => {
    if (!isProduction() && window.innerWidth <= 720) {
      import('vconsole').then((vconsole) => {
        const VConsole = vconsole.default;
        // eslint-disable-next-line no-new
        new VConsole();
      });
    }
  };

  const initGather = () => {
    WKGatherer({
      config: { env: process.env.NEXT_PUBLIC_STAGE || 'develop' },
      data: { ak: 'official_website' },
    });
  };

  useEffect(() => {
    // 初始化埋点sdk
    initGather();
    // 阻止页面缩放
    stopScale();
    // 打开移动端调试工具
    openVConsole();
  }, []);

  // 停用 Google Analytics（分析）
  const disableGA = (disable: boolean) => {
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID}`] = disable;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID_YOU_FIND}`] = disable;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID_NEW}`] = disable;
  };

  // 注册kaamel 服务方法
  useEffect(() => {
    const showTip = DISABLE_LAYOUT_PATHS?.some((item) => {
      return router.asPath?.startsWith(item);
    });
    if (showTip) {
      return;
    }
    const script = document.createElement('script');
    script.src = `/kaamel.js?`;
    script.type = 'text/javascript';
    script.id = 'kaameljs';
    document.getElementsByTagName('head')[0].appendChild(script);
    // js 加载后再去判断
    script.onload = () => {
      console.log('kaameljs onload', window.kaamelObj);
      if (typeof window !== 'undefined' && window.kaamelObj) {
        // 注册kaamel服务
        window.kaamelObj.servicesEffective(['analytics', 'doubleclick', 'googletagmanager']);
        // 判断功能是否启用获取具体适配法律框架
        window.kaamelSetting.onFrameworkLoaded = (frameworkName: 'GDPR' | 'CCPA' | '') => {
          console.log('kaameljs onFrameworkLoaded', frameworkName);
          setFrameworkData({
            finished: true,
            framework: frameworkName,
          });
        };
      }
    };

    if (typeof window !== 'undefined') {
      window.kaamelSetting = {
        googletagmanager: {
          init() {
            console.log('kaamel - googletagmanager - init');
            // setInitGTM(true);
            setKaamelGTMAgree(true);
          },
          unload() {
            console.log('kaamel - googletagmanager - unload');
            // setInitGTM(false);
            setKaamelGTMAgree(false);
          },
        },
        analytics: {
          init() {
            console.log('kaamel - analytics - init', process.env.NEXT_PUBLIC_GA_ID);
            disableGA(false);
            if (process.env.NEXT_PUBLIC_GA_ID) {
              install(process.env.NEXT_PUBLIC_GA_ID, { groups: 'group1' });
              gtag('config', process.env.NEXT_PUBLIC_GA_ID_YOU_FIND, { groups: 'group1' });
              gtag('config', process.env.NEXT_PUBLIC_GA_ID_NEW, { groups: 'group1' });
              setKaamelGAAgree(true);
            }
          },
          unload() {
            disableGA(true);
            console.log('kaamel - analytics - unload');
            setKaamelGAAgree(false);
          },
        },
        doubleclick: {
          init() {
            console.log('kaamel - doubleclick - init');
          },
          unload() {
            console.log('kaamel - doubleclick - unload');
          },
        },
      };
    }
  }, []);

  return (
    <MyContext.Provider value={value as IContextProps}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
        <meta name="applicable-device" content="pc,mobile" />
        <meta httpEquiv="Cache-Control" content="no-transform" />
        {/* @ts-ignore */}
        <link rel="shortcut icon" href="/favicon48x48.ico" mce_href="/favicon48x48.ico" type="image/x-icon" />
        <meta data-n-head="ssr" data-hid="mobile-web-app-capable" name="mobile-web-app-capable" content="yes" />
      </Head>
      <DefaultSeo
        {...SEO}
        dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
        dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      />
      <Script
        strategy="lazyOnload"
        id="taboola"
        dangerouslySetInnerHTML={{
          __html: `
            window._tfa = window._tfa || [];  window._tfa.push({notify: 'event', name: 'page_view', id: 1618542});  !function (t, f, a, x) {         if (!document.getElementById(x)) {            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);         }  }(document.createElement('script'),  document.getElementsByTagName('script')[0],  '//cdn.taboola.com/libtrc/unip/1618542/tfa.js',  'tb_tfa_script')
         `,
        }}
      />
      <Script
        strategy="lazyOnload"
        id="intercom"
        dangerouslySetInnerHTML={{
          __html: `
           window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "tn0n4uq4",
        // name: user.name, // Full name
        // user_id: user.id, // a UUID for your user
        // email: user.email, // the email for your user
        // created_at: user.createdAt // Signup date as a Unix timestamp
      };
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tn0n4uq4';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
         `,
        }}
      />
      <GTMProvider state={{ ...GTM_ARGUS, injectScript: initGTM }}>
        <ParallaxProvider>
          <PageWrapper>
            <Component {...pageProps} />
          </PageWrapper>
        </ParallaxProvider>
      </GTMProvider>
    </MyContext.Provider>
  );
};

export default appWithTranslation(MyApp);
